import React from "react";
import classNames from "~/utils/classNames";
import InputCheckbox from "~/components/elements/InputCheckbox";

export default function LineOption(props) {
  const { option, isSelected, icon, onClick, name } = props;

  return (
    <div key={option.id}>
      <div
        className={classNames(
          isSelected(option)
            ? "z-10 border-active border-2 bg-background-hover"
            : "border-gray-200",
          "relative flex cursor-pointer border p-4 focus:outline-none justify-between rounded-lg hover:bg-background-hover",
        )}
        onClick={() => onClick(option)}
      >
        <div className="flex items-center text-sm gap-x-2">
          <InputCheckbox
            value={isSelected(option)}
            label={option.title}
            icon={icon}
            name={name}
          />
        </div>
        {option.description && (
          <div className="ml-6 pl-1 text-xs md:ml-0 md:pl-0 md:text-right text-gray-500">
            {option.description}
          </div>
        )}
      </div>
    </div>
  );
}
