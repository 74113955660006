import axios from "axios";
import { unionBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PublicBackground from "~/components/shared/PublicBackground";
import headers from "~/utils/headers";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";

export default function PublicSurveyMessagePage(props) {
  const { t } = useTranslation();

  const { id } = props;

  const [survey, setSurvey] = useState(null);
  const [replies, setReplies] = useState([]);

  const questions = survey?.survey_questions || [];

  const findReply = useCallback(
    (questionId) =>
      replies.find(
        (reply) => parseInt(reply.survey_question_id) == parseInt(questionId),
      ),
    [replies],
  );

  const loadSurveyMessage = async () => {
    const res = await axios.get(`/public/survey_messages/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (res.data) {
      setSurvey(res.data.survey);
      setReplies(res.data.survey_question_replies);
      if (
        res.data.survey.survey_questions.length ==
        res.data.survey_question_replies.length
      ) {
        setFormPage("confirm");
      }
    }
  };

  useEffect(loadSurveyMessage, [id]);

  // Form pages
  const formPages = ["form", "confirm"];
  const [formPage, setFormPage] = useState("form");

  // Validate & save

  const [loading, setLoading] = useState(false);

  const isValid = useMemo(
    () =>
      questions
        .filter((q) => q.required)
        .every((question) => findReply(question.id)?.reply),
    [replies],
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const payload = {
      survey_message: {
        survey_question_replies_attributes: survey.survey_questions.map(
          (question) => {
            const question_reply = findReply(question.id);
            return {
              id: question_reply?.id,
              survey_question_id: question.id,
              reply: question_reply?.reply || "",
            };
          },
        ),
      },
    };
    setLoading(true);
    const res = await axios.patch(
      `/public/survey_messages/${id}`,
      payload,
      headers(),
    );
    setReplies(res.data.survey_question_replies);
    setLoading(false);
    setFormPage("confirm");
  };

  const handleReplyChange = (questionId) => (value) => {
    setReplies((prevReplies) =>
      unionBy(
        [
          {
            ...findReply(questionId),
            survey_question_id: questionId,
            reply: value,
          },
        ],
        prevReplies,
        "survey_question_id",
      ),
    );
  };

  return (
    <PublicBackground className="justify-end">
      <div className="bg-white rounded-lg p-4 w-full max-w-sm space-y-2">
        <div className="font-medium">{survey?.title}</div>
        <div className="text-sm">{survey?.subtitle}</div>
      </div>
      {formPage == "form" && (
        <form className="space-y-4 w-full max-w-sm" onSubmit={handleSubmit}>
          <div className="bg-white rounded-lg p-4 space-y-4">
            {questions.map((question) => (
              <Input
                key={question.id}
                label={question.title}
                value={findReply(question.id)?.reply || ""}
                onChange={handleReplyChange(question.id)}
                optional={!question.required}
                required={question.required}
                type={question.field_type}
                autoComplete={question.field_type}
              />
            ))}
          </div>
          <Button
            className="w-full"
            style="primary"
            label={t("surveys.questions.submit")}
            disabled={!isValid}
            loading={loading}
          />
        </form>
      )}
      {formPage == "confirm" && (
        <div className="space-y-4 w-full max-w-sm">
          <div className="bg-white rounded-lg p-4 space-y-4">
            {questions.map((question) => (
              <div className="space-y-2" key={question.id}>
                <div className="font-medium text-headings">
                  {question.title}
                </div>
                <div className="text-sm text-muted">
                  {findReply(question.id)?.reply || (
                    <span className="italic">{t("shared.not_answered")}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Button
            label={t("surveys.questions.edit_replies")}
            onClick={() => setFormPage("form")}
            className="w-full"
            style="primary"
          />
        </div>
      )}
    </PublicBackground>
  );
}
