import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import InputCheckbox from "~/components/elements/InputCheckbox";
import Button from "~/components/elements/Button";
import FacebookIcon from "~/components/shared/icons/FacebookIcon";
import {
  ArrowRightCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import Input from "../../../elements/Input";
import Loader from "../../../utils/Loader";
import { UserContext } from "../../../../contexts/user-context";
import ContactHeader from "../../../contacts/ContactHeader";
import { UIContext } from "../../../../contexts/ui-context";

export default function ConnectInstagramChecklist(props) {
  const { showTutorial } = props;

  const { organization, businessDiscovery, updateOrganization } =
    useContext(UserContext);
  const { showVideo } = useContext(UIContext);

  const { t } = useTranslation();

  const [username, setUsername] = useState(organization?.username || "");
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [account, setAccount] = useState(null);

  const [checkInstagram, setCheckInstagram] = useState(false);
  const [checkFacebookPage, setCheckFacebookPage] = useState(false);

  const handleSearchUsername = async () => {
    if (!username) return;
    setLoadingAccount(true);
    const results = await businessDiscovery(username);
    if (results) {
      setAccount(results);
      updateOrganization({ username });
    } else {
      setAccount("not_found");
    }
    setLoadingAccount(false);
  };

  useEffect(handleSearchUsername, [username]);

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <div className="text-lg leading-6 font-medium">
          {t("onboarding.connect_instagram.connect_title")}
        </div>
        <p className="text-base leading-5">
          <Trans i18nKey="onboarding.connect_instagram.connect_text" />
        </p>
      </div>

      <div className="space-y-4">
        <form
          className="space-y-2"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleSearchUsername();
          }}
        >
          <Input
            label={t("onboarding.connect_instagram.username_label")}
            placeholder={t("onboarding.connect_instagram.username_placeholder")}
            value={username}
            onChange={setUsername}
            leadingAddon="@"
            trailingAddon={
              loadingAccount ? (
                <Loader width={20} />
              ) : (
                <ArrowRightCircleIcon
                  className="w-5 text-neutral-500 cursor-pointer"
                  onClick={handleSearchUsername}
                />
              )
            }
            debounce
            autoFocus
          />
          {/* <Button
          onClick={handleSearchUsername}
          label={t("onboarding.connect_instagram.username_button")}
          className="w-full"
          icon={MagnifyingGlassIcon}
          loading={loadingAccount}
          style={account ? "default" : "primary"}
        /> */}
        </form>

        {account == "not_found" && (
          <div className="p-4 space-y-4 rounded-2xl rounded-tl-none bg-highlight">
            <div className="flex items-center space-x-2">
              <QuestionMarkCircleIcon className="w-5" />
              <div className="font-medium text-sm">
                {t("onboarding.connect_instagram.username_error")}
              </div>
            </div>
            <Button
              label={t("onboarding.connect_instagram.username_error_button")}
              className="w-full"
              onClick={() =>
                showVideo({
                  title: t(`onboarding.connect_instagram.help.switch`),
                  url: `/videos/onboarding_switch.mp4`,
                })
              }
            />
          </div>
        )}

        {account && account != "not_found" && (
          <div className="space-y-4">
            <div className="p-3 bg-lighter-gray rounded-xl group flex flex-col items-start space-y-3">
              <div className="w-full flex items-center justify-between">
                <ContactHeader contact={account} />
                <div className="flex space-x-4">
                  <div className="flex flex-col items-center">
                    <div className="text-md font-medium">
                      {account.followers_count || "-"}
                    </div>
                    <div className="text-2xs">
                      {t("profile.external_accounts.instagram.followers")}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-md font-medium">
                      {account.follows_count || "-"}
                    </div>
                    <div className="text-2xs">
                      {t("profile.external_accounts.instagram.following")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-sm leading-4">
              <Trans i18nKey="onboarding.connect_instagram.facebook_page_text" />
            </div>

            <InputCheckbox
              label={
                <Trans i18nKey="onboarding.connect_instagram.connect_facebook_check" />
              }
              value={checkFacebookPage}
              onClick={setCheckFacebookPage}
            />

            <Button
              href="/users/auth/instagram"
              label={t("onboarding.connect_instagram.connect_cta")}
              style="primary"
              icon={FacebookIcon}
              className="w-full"
              disabled={!checkFacebookPage}
              loadingOnClick
            />
          </div>
        )}
      </div>

      {showTutorial && (
        <Button
          className="w-full"
          icon={QuestionMarkCircleIcon}
          label={t("onboarding.connect_instagram.show_me_how")}
          onClick={showTutorial}
        />
      )}

      <div className="text-2sm leading-4 text-muted whitespace-pre-line">
        <Trans i18nKey="onboarding.connect_instagram.disclaimer">
          <a
            href="https://developers.facebook.com/docs/development/release/tech-providers"
            target="_blank"
            className="underline"
          >
            Tech Provider
          </a>
          <a
            href="https://developers.facebook.com/terms"
            target="_blank"
            className="underline"
          >
            Developer Platform Terms
          </a>
        </Trans>
      </div>
    </div>
  );
}
