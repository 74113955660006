import { XMarkIcon } from "@heroicons/react/20/solid";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AutomationContext } from "~/contexts/automation-context";
import ScenarioExecution from "./ScenarioExecution";
import CampaignExecution from "../../../campaigns/CampaignExecution";
import Loader from "~/components/utils/Loader";
import { CampaignsContext } from "~/contexts/campaigns-context";
import subscribeToChannel from "~/utils/subscribeToChannel";
import { unionBy } from "lodash";

export default function ScenarioExecutions(props) {
  const { conversationId, onClose } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const { loadScenarioExecutions } = useContext(AutomationContext);
  const { loadCampaignExecutions } = useContext(CampaignsContext);

  const [scenarioExecutions, setScenarioExecutions] = useState([]);
  const [campaignExecutions, setCampaignExecutions] = useState([]);

  const cableSubscription = useRef(null);

  const subscribeToAutomationChannel = useCallback(
    (conversationId) => {
      cableSubscription.current = subscribeToChannel(
        `ConversationAutomationChannel`,
        (data) => {
          console.log({ data });
          if (data.scenario_execution) {
            setScenarioExecutions(unionBy([data], scenarioExecutions, "id"));
          }
          if (data.campaign_execution) {
            setCampaignExecutions(unionBy([data], campaignExecutions, "id"));
          }
        },
        { conversation_id: conversationId },
      );
    },
    [setScenarioExecutions, setCampaignExecutions],
  );

  const tabs = [
    {
      label: t("automation.scenarios.title"),
      value: "scenario",
    },
    {
      label: t("campaigns.title"),
      value: "campaign",
    },
  ];
  const [activeTab, setActiveTab] = useState("scenario");

  const [openExecutionId, setOpenExecutionId] = useState();

  useEffect(async () => {
    const scenarioExecutions = await loadScenarioExecutions({
      conversation_id: conversationId,
      limit: 20,
    });
    const campaignExecutions = await loadCampaignExecutions({
      conversation_id: conversationId,
      limit: 20,
    });
    setScenarioExecutions(scenarioExecutions);
    setCampaignExecutions(campaignExecutions);
    setOpenExecutionId(scenarioExecutions[0]?.id);
    setLoading(false);

    subscribeToAutomationChannel(conversationId);

    return () => cableSubscription.current?.disconnect();
  }, [conversationId]);

  useEffect(() => {
    setOpenExecutionId(
      activeTab == "scenario"
        ? scenarioExecutions[0]?.id
        : campaignExecutions[0]?.id,
    );
  }, [activeTab]);

  return (
    <div className="flex-shrink-0 border-l p-3 w-72 hidden sm:flex flex-col overflow-hidden">
      <div className="flex items-center mb-3">
        <div className="font-medium flex-grow">
          {t("automation.scenarios.executions.title")}
        </div>
        <button className="p-1" onClick={onClose}>
          <XMarkIcon className="w-5" />
        </button>
      </div>

      <div
        className={`grid grid-cols-2 gap-1.5 p-1.5 bg-lighter-gray rounded-xl mb-3`}
      >
        {tabs.map((tab, index) => (
          <button
            key={tab.value}
            className={`rounded-lg h-8 flex items-center justify-center space-x-2 ${
              tab.value == activeTab
                ? "bg-white"
                : "hover:bg-white hover:bg-opacity-30 opacity-75 hover:opacity-100 transition-opacity"
            }`}
            onClick={() => setActiveTab(tab.value)}
          >
            <div className="font-medium text-md">{tab.label}</div>
          </button>
        ))}
      </div>
      {loading && (
        <div className="p-4 flex items-center justify-center">
          <Loader width={28} strokeWidth={8} />
        </div>
      )}
      {activeTab == "scenario" && (
        <div className="flex-grow flex flex-col space-y-3 pb-3 overflow-auto">
          {scenarioExecutions.map((execution, index) => (
            <ScenarioExecution
              key={index}
              execution={execution}
              setExecution={(value) =>
                setScenarioExecutions(
                  scenarioExecutions.map((e) =>
                    e.id == execution.id ? { ...e, ...value } : e,
                  ),
                )
              }
              opened={execution.id == openExecutionId}
              onOpen={() => setOpenExecutionId(execution.id)}
            />
          ))}
        </div>
      )}
      {activeTab == "campaign" && (
        <div className="flex-grow flex flex-col space-y-3 pb-3 overflow-auto">
          {campaignExecutions.map((execution, index) => (
            <CampaignExecution
              key={index}
              execution={execution}
              opened={execution.id == openExecutionId}
              onOpen={() => setOpenExecutionId(execution.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
