import React, { useContext, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { AutomationContext } from "~/contexts/automation-context";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Table from "~/components/elements/table/Table";
import Button from "~/components/elements/Button";
import {
  FireIcon,
  PencilSquareIcon,
  BoltIcon,
} from "@heroicons/react/20/solid";
import classNames from "~/utils/classNames";
import { sortBy } from "lodash";

export default function ActiveScenarios(props) {
  const { t } = useTranslation();
  const { scenarios, loadScenario, loadScenarios } =
    useContext(AutomationContext);
  const firstFiveScenarios = useMemo(() => {
    const filtedScenarios = scenarios.filter(
      (scenario) => scenario.active && scenario.last_executed_at,
    );
    return sortBy(filtedScenarios, "last_executed_at").reverse().slice(0, 5);
  }, [scenarios]);

  const columns = useMemo(
    () => [
      {
        label: t("dashboard.scenarios.name"),
        accessor: (scenario) => {
          return (
            <div className="flex flex-col gap-y-1">
              <Link
                className="flex items-end text-headings font-semibold hover:underline cursor-pointer"
                to={`/automation/scenarios/${scenario.id}/history`}
              >
                {scenario.title}
              </Link>
              <div>
                <span
                  className={classNames(
                    "text-xs text-muted",
                    scenario.last_executed_at ? "" : "invisible",
                  )}
                >
                  {t("dashboard.scenarios.last_executed", {
                    relativeDate: DateTime.fromISO(
                      scenario.last_executed_at,
                    ).toRelative(),
                  })}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        label: t("dashboard.scenarios.executions"),
        accessor: (scenario) => {
          return (
            <div className="flex items-center text-headings">
              <BoltIcon className="h-5 w-5 mr-2" />
              <span>{scenario.metrics?.executions?.total}</span>
            </div>
          );
        },
      },
      {
        label: t("dashboard.scenarios.conversions"),
        accessor: (scenario) => {
          return (
            <div className="flex items-center text-headings">
              <FireIcon className="h-5 w-5 mr-2" />
              <span>{scenario.metrics?.conversions?.total}</span>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className="flex flex-col gap-y-4">
      <h2 className="font-bold text-headings text-xl">
        {t("dashboard.scenarios.title", {
          count: scenarios?.filter((scenario) => scenario.active).length || 0,
        })}
      </h2>
      <Table
        items={firstFiveScenarios}
        columns={columns}
        onRowRender={(scenario) => loadScenario(scenario.id)}
        emptyState={
          <div className="flex flex-col space-y-4 min-h-80 items-center justify-center">
            <div className="text-center">{t("dashboard.scenarios.empty")}</div>
            <Button
              icon={BoltIcon}
              label={t("dashboard.scenarios.empty_cta")}
              style="primary"
              to="/campaigns?new=true"
            />
          </div>
        }
      />
      {scenarios.length > 0 && (
        <Link
          to="/automation/scenarios"
          className="text-muted w-full text-center font-medium py-2"
        >
          {t("dashboard.scenarios.view_all")}
        </Link>
      )}
    </div>
  );
}
