import React, { useContext, useEffect, useMemo, useState } from "react";
import PageTitle from "~/components/shared/PageTitle";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CampaignsContext } from "~/contexts/campaigns-context";
import Button from "~/components/elements/Button";
import CampaignItem from "./CampaignItem";
import { reverse, sortBy, uniqBy } from "lodash";
import EditCampaign from "./EditCampaign";
import Modal from "~/components/shared/Modal";
import { PlusIcon } from "@heroicons/react/20/solid";
import PaginationPages from "~/components/shared/PaginationPages";
import CampaignResults from "./CampaignResults";
import NewCampaignDialog from "./NewCampaignDialog";

const CAMPAIGNS_PER_PAGE = 10;

export default function CampaignsPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { campaigns, loadCampaigns } = useContext(CampaignsContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") || 1);

  const numberOfPages = useMemo(
    () => Math.ceil(campaigns.length / CAMPAIGNS_PER_PAGE),
    [campaigns.length],
  );

  const { campaignId } = useParams();

  const activeCampaign = campaigns.find(
    (campaign) => campaign.id == campaignId,
  );

  const [newCampaign, showNewCampaign] = useState(
    searchParams.get("new") ? {} : false,
  );

  useEffect(() => setSearchParams({ page }), [page]);

  useEffect(loadCampaigns, []);

  const handleNewCampaign = () => {
    showNewCampaign({});
  };

  const visibleCampaigns = reverse(
    sortBy(uniqBy(campaigns, "id"), "created_at"),
  ).slice((page - 1) * CAMPAIGNS_PER_PAGE, page * CAMPAIGNS_PER_PAGE);

  return (
    <>
      <div className="w-full px-4 sm:px-6 lg:px-8 pt-3 sm:pt-6 overflow-y-auto">
        <div className="space-y-4 sm:space-y-6">
          <div className="px-3 sm:px-0 space-y-4 sm:space-y-6">
            <PageTitle text={t("campaigns.title")} />
            <div className="flex items-center justify-between">
              <div className="text-md">
                {t("campaigns.count", {
                  count: campaigns.length,
                })}
              </div>
              <Button
                label={t("campaigns.new_campaign")}
                onClick={handleNewCampaign}
                icon={PlusIcon}
                style="primary"
              />
            </div>
          </div>
          <div className="divide-y divide-neutral-200 border-t">
            {visibleCampaigns.map((campaign) => (
              <CampaignItem key={campaign.id} campaign={campaign} />
            ))}
          </div>
          <div className="px-4 pb-10 flex items-center justify-center">
            <PaginationPages
              onClick={setPage}
              currentPage={page}
              numberOfPages={numberOfPages}
            />
          </div>
        </div>
      </div>
      {activeCampaign && activeCampaign.status == "draft" ? (
        <Modal
          onClose={() => navigate("/campaigns")}
          className="h-full !w-full max-w-6xl !p-0"
          checkDirty
        >
          <EditCampaign initialValues={activeCampaign} />
        </Modal>
      ) : null}
      {activeCampaign &&
      ["completed", "running", "limited", "canceled"].includes(
        activeCampaign.status,
      ) ? (
        <Modal
          onClose={() => navigate("/campaigns")}
          className="h-full !w-full max-w-6xl !p-0"
          checkDirty
        >
          <CampaignResults campaignId={activeCampaign?.id} />
        </Modal>
      ) : null}
      {newCampaign ? (
        <NewCampaignDialog onClose={() => showNewCampaign(false)} />
      ) : null}
    </>
  );
}
