import React from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import InputSelect from "../../elements/InputSelect";
import { sortBy } from "lodash";
import InputCheckbox from "../../elements/InputCheckbox";
import ContactPropertyPickerField from "../../contacts/properties/ContactPropertyPickerField";

const fieldTypes = ["text", "email", "phone", "date", "number", "textarea"];

export default function SurveyQuestionsForm(props) {
  const { t } = useTranslation();

  const { questions, setQuestions } = props;

  const visibleQuestions = questions.filter((o) => !o._destroy);

  const handleChangeQuestion = (index, field) => (value) => {
    setQuestions((questions) =>
      questions.map((question, i) =>
        i === index ? { ...question, [field]: value } : question,
      ),
    );
  };

  const addQuestion = () => {
    setQuestions((questions) => [
      ...questions,
      { title: "", field_type: "text" },
    ]);
  };

  const removeQuestion = (index) => {
    const question = questions[index];
    if (question.id) {
      setQuestions((questions) =>
        questions.map((o) =>
          o.id === question.id ? { ...o, _destroy: true } : o,
        ),
      );
    } else {
      setQuestions((questions) => questions.filter((_, i) => i !== index));
    }
  };

  const fieldTypeOptions = fieldTypes.map((type) => ({
    label: t(`surveys.questions.field_types.${type}`),
    value: type,
  }));

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="font-medium text-sm">
          {t("surveys.questions.count", { count: visibleQuestions.length })}
        </div>
        <Button
          size="small"
          onClick={addQuestion}
          label={t(`surveys.questions.add_question`)}
          icon={PlusIcon}
        />
      </div>
      <div className="space-y-4">
        {questions.map(
          (question, index) =>
            !question._destroy && (
              <div
                key={index}
                className="border p-2 rounded-md relative group mb-3"
              >
                <button
                  className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center bg-white border shadow rounded-full text-darker-gray opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => removeQuestion(index)}
                >
                  <XMarkIcon className="w-3" />
                </button>
                <div className="space-y-2">
                  <Input
                    placeholder={t(`surveys.questions.title_placeholder`)}
                    value={question.title}
                    onChange={handleChangeQuestion(index, "title")}
                    autoFocus={!question.title}
                    emojiPicker
                  />
                  <div className="flex space-x-3 items-center">
                    <div className="flex-grow">
                      <InputSelect
                        options={fieldTypeOptions}
                        value={question.field_type}
                        onChange={handleChangeQuestion(index, "field_type")}
                      />
                    </div>
                    <InputCheckbox
                      value={question.required}
                      onClick={handleChangeQuestion(index, "required")}
                      label={t("shared.required")}
                    />
                  </div>
                  <div className="flex items-center space-x-2 mb-2 overflow-hidden">
                    <div className="text-xs flex-grow whitespace-nowrap flex-shrink-0">
                      {t("surveys.questions.save_to_property")}
                    </div>
                    <ContactPropertyPickerField
                      value={question.contact_property}
                      onChange={handleChangeQuestion(index, "contact_property")}
                    />
                  </div>
                </div>
              </div>
            ),
        )}
      </div>
    </div>
  );
}
