import React from "react";

import { Outlet } from "react-router-dom";

import MobileTabBar from "./MobileTabBar";
import DesktopSidebar from "./DesktopSidebar";
import OnboardingChecklist from "../onboarding/OnboardingChecklist";

export default function Layout() {
  return (
    <div className="sm:flex sm:flex-row sm:w-screen sm:items-stretch max-h-screen overflow-hidden">
      <DesktopSidebar />
      <OnboardingChecklist />
      <Outlet />
      <MobileTabBar />
    </div>
  );
}
