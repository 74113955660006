import React, { useContext, useEffect, useState } from "react";
import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import { ContactsContext } from "../../contexts/contacts-context";
import { UIContext } from "../../contexts/ui-context";
import Input from "../elements/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import ContactHeader from "./ContactHeader";
import Loader from "../utils/Loader";

function ContactResult(props) {
  const { contact: initialContact, onClick } = props;

  const [contact, setContact] = useState(initialContact);
  const [loading, setLoading] = useState(true);

  const { loadContact } = useContext(ContactsContext);

  useEffect(async () => {
    if (loading) {
      const res = await loadContact(contact.id);
      setContact(res);
      setLoading(false);
    }
  }, [contact.id]);

  return (
    <div
      onClick={onClick}
      className="px-2 py-1.5 rounded-lg hover:bg-neutral-100 transition-colors cursor-pointer"
    >
      <ContactHeader contact={contact} loading={loading} />
    </div>
  );
}

export default function ContactPickerDialog(props) {
  const { t } = useTranslation();

  const { onClose, onPick = () => {}, confirmPrompt = null } = props;

  const { loadContacts } = useContext(ContactsContext);
  const { showAlert } = useContext(UIContext);

  const handlePick = (contactId) => {
    if (confirmPrompt) {
      showAlert({
        title: confirmPrompt,
        confirm: t("shared.yes"),
        onSubmit: () => {
          onPick(contactId);
          onClose();
        },
      });
    } else {
      onPick(contactId);
      onClose();
    }
  };

  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(async () => {
    setLoading(true);
    const res = await loadContacts(
      searchQuery.length > 0
        ? { search: searchQuery, limit: 8 }
        : {
            sort_by: "last_message_at",
            sort_direction: "desc",
            limit: 8,
          },
    );
    setContacts(res.contacts);
    setLoading(false);
  }, [searchQuery]);

  return (
    <Modal onClose={onClose} zIndex={1100}>
      <div className="max-w-full w-96 max-h-[300px] flex flex-col overflow-hidden p-3 space-y-3">
        <div className="flex-none">
          <Input
            placeholder={t("contacts.search_contacts")}
            icon={MagnifyingGlassIcon}
            value={searchQuery}
            onChange={setSearchQuery}
            debounce={300}
            autoFocus
          />
        </div>
        {loading && (
          <div className="flex items-center justify-center py-2">
            <Loader />
          </div>
        )}
        <div className="overflow-auto flex-grow">
          {contacts.map((contact) => (
            <ContactResult
              key={contact.id}
              contact={contact}
              onClick={() => handlePick(contact.id)}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}
