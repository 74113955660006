import React, { useContext } from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";

import ElementsPage from "~/components/elements/ElementsPage";
import ErrorPage from "~/components/utils/ErrorPage";
import { UserContext } from "~/contexts/user-context";
import AgentPage from "../agent/AgentPage";
import ConversionLinksPage from "../automation/conversion_links/ConversionLinksPage";
import ScenariosPage from "../automation/scenarios/ScenariosPage";
import SurveysPage from "../surveys/SurveysPage";
import CalendarPage from "../calendar/CalendarPage";
import CampaignsPage from "../campaigns/CampaignsPage";
import ContactsPage from "../contacts/ContactsPage";
import ContentPage from "../content/ContentPage";
import DashboardPage from "../dashboard/DashboardPage";
import InboxPage from "../inbox/InboxPage";
import OnboardingScreen from "../onboarding/OnboardingScreen";
import PaymentsPage from "../payments/PaymentsPage";
import ProfilePage from "../profile/ProfilePage";
import ComingSoon from "./ComingSoon";
import Layout from "./Layout";

function Root() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        {/* Inbox Settings */}
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="inbox/settings" element={<InboxPage settings />} />
        <Route path="inbox/settings/:path" element={<InboxPage settings />} />
        {/* Inbox Conversation */}
        {["inbox", "inbox/folders/:folderId", "inbox/segments/:segmentId"].map(
          (root, index) => (
            <React.Fragment key={index}>
              <Route
                path={`${root}/conversations/:conversationId`}
                element={<InboxPage />}
              />
              <Route path={`${root}/conversations`} element={<InboxPage />} />
              <Route path={root} element={<InboxPage />} />
            </React.Fragment>
          ),
        )}

        {/* Contacts */}
        <Route path="contacts" element={<ContactsPage />} />
        <Route
          path="contacts/properties"
          element={<ContactsPage showProperties />}
        />
        <Route path="contacts/:contactId" element={<ContactsPage />} />
        <Route
          path="contacts/conversations/:conversationId"
          element={<ContactsPage />}
        />
        <Route
          path="contacts/conversations/:conversationId/automation"
          element={<ContactsPage />}
        />

        {/* Campaigns */}
        <Route path="campaigns" element={<CampaignsPage />} />
        <Route path="campaigns/:campaignId" element={<CampaignsPage />} />

        {/* AI Agent */}
        <Route path="agent" element={<AgentPage />} />
        <Route path="agent/:tab" element={<AgentPage />} />

        {/* Instagram Content */}
        <Route path="content" element={<ContentPage />} />
        <Route path="content/:tab" element={<ContentPage />} />
        <Route path="content/:tab/:resourceId" element={<ContentPage />} />

        {/* Calendar */}
        <Route path="calendar" element={<CalendarPage />} />
        <Route
          path="calendar/settings"
          element={<CalendarPage showSettings />}
        />
        <Route
          path="calendar/settings/terms_of_sale"
          element={<CalendarPage showSettings />}
        />
        <Route path="calendar/:appointmentId" element={<CalendarPage />} />
        <Route
          path="calendar/:appointmentId/details"
          element={<CalendarPage />}
        />
        <Route
          path="calendar/:appointmentId/details/:path"
          element={<CalendarPage />}
        />

        {/* Payments */}
        <Route path="payments" element={<PaymentsPage />} />
        <Route path="payments/balance" element={<PaymentsPage showBalance />} />
        <Route path="payments/:paymentId" element={<PaymentsPage />} />

        {/* Automation */}
        <Route
          path="automation/conversion_links/:linkId"
          element={<ConversionLinksPage />}
        />
        <Route
          path="automation/conversion_links"
          element={<ConversionLinksPage />}
        />
        <Route path="automation/surveys/:surveyId" element={<SurveysPage />} />
        <Route path="automation/surveys" element={<SurveysPage />} />
        <Route
          path="automation/scenarios/:scenarioId/history"
          element={<ScenariosPage showResults />}
        />
        <Route
          path="automation/scenarios/:scenarioId"
          element={<ScenariosPage />}
        />
        <Route path="automation/scenarios" element={<ScenariosPage />} />
        <Route path="automation" element={<ScenariosPage />} />

        {/* Profile */}
        <Route path="profile" element={<ProfilePage />} />
        <Route path="profile/:path" element={<ProfilePage />} />
        <Route path="profile/:path/:subpath" element={<ProfilePage />} />

        {/* Elements */}
        <Route path="elements" element={<ElementsPage />} />

        <Route path="*" element={<ComingSoon />} />
      </Route>
    </Routes>
  );
}

// TODO switch to BrowserRouter with object notation instead of components </> notation
export default function MainRouter() {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
    },
  ]);

  const { organization } = useContext(UserContext);

  if (!organization.onboarding_completed) return <OnboardingScreen />;

  return <RouterProvider router={router} />;
}
