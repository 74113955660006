import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import InputCheckbox from "~/components/elements/InputCheckbox";
import { AutomationContext } from "~/contexts/automation-context";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "../../contexts/user-context";
import ButtonGroup from "../elements/ButtonGroup";
import TemplateMessage from "../messages/TemplateMessage";
import SurveyOptionsForm from "./options/SurveyOptionsForm";
import SurveyOptionsResults from "./options/SurveyOptionsResults";
import SurveyQuestionsForm from "./questions/SurveyQuestionsForm";
import SurveyQuestionsPreview from "./questions/SurveyQuestionsPreview";

export default function SurveyForm(props) {
  const { t } = useTranslation();

  const { saveSurvey, destroySurvey } = useContext(AutomationContext);
  const { showPrompt } = useContext(UIContext);
  const { organization } = useContext(UserContext);

  const {
    handleClose,
    onSave = () => {},
    survey: initialSurvey = {
      title: "",
      subtitle: "",
      survey_type: "single_choice",
      survey_options: [],
      survey_questions: [],
    },
  } = props;

  const [survey, setSurvey] = useState(initialSurvey);
  const [options, setOptions] = useState(initialSurvey?.survey_options || []);
  const [questions, setQuestions] = useState(
    initialSurvey?.survey_questions || [],
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const visibleOptions = options.filter((o) => !o._destroy);
  const visibleQuestions = questions.filter((o) => !o._destroy);

  const handleChange = (field) => (value) => {
    setSurvey((survey) => ({ ...survey, [field]: value }));
  };

  // Survey type

  const surveyTypeButtons = ["single_choice", "questions"].map((type) => ({
    label: t(`surveys.survey_types.${type}`),
    style: survey.survey_type === type ? "primary" : "default",
    onClick: () => setSurvey((survey) => ({ ...survey, survey_type: type })),
  }));

  // Validation & saving

  const isOptionValid = (option) => option.title.trim().length > 0;
  const isQuestionValid = (question) => question.title.trim().length > 0;

  const isOptionsValid =
    visibleOptions.length > 0 && visibleOptions.every(isOptionValid);
  const isQuestionsValid =
    visibleQuestions.length > 0 &&
    visibleQuestions.every(isQuestionValid) &&
    survey.button_label?.trim()?.length > 0;

  const isValid =
    survey.title?.trim()?.length > 0 &&
    ((survey.survey_type == "single_choice" && isOptionsValid) ||
      (survey.survey_type == "questions" && isQuestionsValid));

  const [loadingSave, setLoadingSave] = useState(false);

  const handleSave = async () => {
    if (!isValid) return;

    setLoadingSave(true);

    const newSurvey = await saveSurvey({
      ...survey,
      id: survey.id ? parseInt(survey.id) : null,
      title: survey.title.trim(),
      subtitle: survey.subtitle?.trim(),
      survey_options_attributes: options,
      survey_questions_attributes: questions,
    });

    setLoadingSave(false);
    handleClose();
    onSave(newSurvey);
  };

  const handleDelete = () => {
    showPrompt(t("surveys.delete_confirm"), () => {
      destroySurvey(survey.id);
      handleClose();
    });
  };

  // Preview & results

  const previewPages =
    survey.survey_type == "questions"
      ? ["template", "form"]
      : ["template", "results"];
  const [previewPage, setPreviewPage] = useState("template");

  // switch preview page when changing questions
  useEffect(() => {
    if (questions.length > 0) setPreviewPage("form");
  }, [questions]);

  const templatePayload = useMemo(() => {
    const buttons =
      survey.survey_type == "single_choice"
        ? visibleOptions
        : [
            {
              title: survey.button_label || t("surveys.default_button_label"),
            },
          ];
    return survey.title
      ? {
          title: survey.title,
          subtitle: survey.subtitle,
          buttons,
        }
      : null;
  }, [survey, visibleOptions]);

  const optionResults = useMemo(
    () =>
      visibleOptions?.map((option) => ({
        title: option.title,
        replies: option.reply_count,
      })) || [],
    [survey, visibleOptions],
  );

  return (
    <div className={`flex-grow flex flex-col overflow-hidden`}>
      <div className="flex flex-col sm:flex-row items-stretch overflow-hidden">
        <div className="w-96 space-y-4 p-4 overflow-y-auto">
          <Input
            label={
              survey.id ? t("surveys.edit_survey") : t("surveys.new_survey")
            }
            placeholder={t("surveys.title_placeholder")}
            value={survey.title}
            onChange={handleChange("title")}
            autoFocus={!survey.title}
            maxLength={80}
            emojiPicker
          />
          <Input
            type="textarea"
            placeholder={t("surveys.subtitle_placeholder")}
            value={survey.subtitle}
            onChange={handleChange("subtitle")}
            maxLength={80}
            emojiPicker
          />
          {organization.dev && (
            <ButtonGroup buttons={surveyTypeButtons} className="w-full" />
          )}
          {survey.survey_type == "single_choice" && (
            <>
              <InputCheckbox
                label={t("surveys.limit_replies")}
                value={survey.limit_replies}
                onClick={handleChange("limit_replies")}
                className="px-1 py-1"
              />
              <SurveyOptionsForm options={options} setOptions={setOptions} />
            </>
          )}
          {survey.survey_type == "questions" && (
            <>
              <Input
                placeholder={t("surveys.button_label_placeholder")}
                value={survey.button_label}
                onChange={handleChange("button_label")}
                maxLength={20}
                emojiPicker
              />
              <SurveyQuestionsForm
                questions={questions}
                setQuestions={setQuestions}
              />
            </>
          )}
        </div>
        <div className="w-full sm:w-96 py-4 sm:py-8 bg-lighter-gray hidden sm:flex flex-col space-y-8 items-center justify-center">
          <ButtonGroup
            buttons={previewPages.map((page) => ({
              label: t(`surveys.preview.${page}`),
              style: previewPage === page ? "primary" : "default",
              onClick: () => setPreviewPage(page),
            }))}
          />
          {previewPage == "template" && templatePayload && (
            <TemplateMessage template={templatePayload} />
          )}
          {previewPage == "form" && survey.survey_type == "questions" && (
            <SurveyQuestionsPreview
              survey={survey}
              questions={visibleQuestions}
            />
          )}
          {previewPage == "results" &&
            survey.survey_type == "single_choice" && (
              <div className="bg-white rounded-lg border p-3">
                <div className="text-xs font-medium text-muted mb-2">
                  {t("shared.results")}
                </div>
                <SurveyOptionsResults options={optionResults} />
              </div>
            )}
        </div>
      </div>
      <div className="border-t p-4 flex items-center justify-between">
        <div className="flex space-x-2">
          <Button label={t("shared.cancel")} onClick={handleClose} />
          {survey?.id ? (
            <Button
              label={t("shared.delete")}
              onClick={handleDelete}
              style="danger"
            />
          ) : null}
        </div>
        <div className="flex items-center space-x-2">
          {errorMessage && (
            <div className="text-red text-sm text-medium">{errorMessage}</div>
          )}
          <Button
            label={t("shared.save")}
            style="primary"
            onClick={handleSave}
            disabled={!isValid}
            loading={loadingSave}
          />
        </div>
      </div>
    </div>
  );
}
