import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { UserContext } from "../../contexts/user-context";
import { NewspaperIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import classNames from "../../utils/classNames";
import { Link } from "react-router-dom";

export default function OnboardingChecklist() {
  const { t } = useTranslation();

  const { organization, loadOrganization } = useContext(UserContext);

  const [hidden, setHidden] = useState(false);

  const checklistItems = useMemo(
    () => [
      // {
      //   label: "dm_inro",
      //   path: "https://ig.me/m/inro.social",
      // },
      {
        label: "create_folder",
        path: "/inbox",
      },
      {
        label: "move_to_folder",
        path: "/inbox",
      },
      {
        label: "create_scenario",
        path: "/automation/scenarios",
      },
      {
        label: "trigger_scenario",
        path: "https://ig.me/m/" + organization?.username,
      },
      {
        label: "send_campaign",
        path: "/campaigns",
      },
    ],
    [organization],
  );

  const checklistCompleted = useMemo(
    () =>
      Object.keys(organization?.onboarding_checklist)?.every(
        (item) => organization?.onboarding_checklist[item],
      ),
    [organization?.onboarding_checklist],
  );

  const refreshInterval = useRef(null);

  useEffect(() => {
    if (!checklistCompleted) {
      refreshInterval.current = setInterval(loadOrganization, 3000);
    }
    return () => {
      clearInterval(refreshInterval.current);
    };
  }, [checklistCompleted]);

  if (checklistCompleted) {
    return null;
  }

  if (hidden) return null;

  return (
    <div className="hidden sm:flex flex-col space-y-4 fixed z-50 bottom-4 left-[88px] xl:left-[308px] w-[272px] bg-help p-4 rounded-2xl rounded-tl-none text-white">
      <div className="space-y-2">
        <div className="flex space-x-2 items-center">
          <NewspaperIcon className="w-5" />
          <h2 className="text-base leading-5 font-medium flex-grow">
            {t("onboarding.checklist.title")}
          </h2>
          <XMarkIcon
            className="w-5 cursor-pointer"
            onClick={() => setHidden(true)}
          />
        </div>
        <p className="text-sm leading-4">
          {t("onboarding.checklist.subtitle")}
        </p>
      </div>
      <div className="bg-help-hover rounded-lg py-3 space-y-3">
        {checklistItems.map((item, index) => (
          <Link
            className={classNames(
              "px-3 flex items-start space-x-2 cursor-pointer",
              organization?.onboarding_checklist[item.label] && "opacity-75",
            )}
            to={item.path}
            key={item.label}
            target={item.path.includes("https") ? "_blank" : null}
          >
            <div className="w-4 h-4 mt-0.5 rounded-full bg-white text-xs font-bold text-help flex items-center justify-center">
              {index + 1}
            </div>
            <p
              className={classNames(
                "text-sm leading-5",
                organization?.onboarding_checklist[item.label] &&
                  "line-through",
              )}
            >
              {t(`onboarding.checklist.${item.label}`)}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}
