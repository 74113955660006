import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { isApp } from "~/utils/environment";
import extractTransformPosition from "~/utils/extractTransformPosition";

import { InboxContext } from "~/contexts/inbox-context";
import { UserContext } from "~/contexts/user-context";
import { useTranslation } from "react-i18next";
import { UIContext } from "~/contexts/ui-context";
import CheckCircle from "~/components/shared/CheckCircle";
import ConversationItemInfo from "./ConversationItemInfo";
import ConversationStatusIcon from "./ConversationStatusIcon";
import FoldersDrawer from "./folders/FoldersDrawer";

export default function ConversationItem(props) {
  const {
    conversation,
    active,
    activeFolder,
    scrollingRef,
    selecting,
    setSelecting = () => {},
    selected,
    onSelect = () => {},
    onSwipeMove,
    onSwipeEnd,
    showStatus = true,
    smaller = false,
  } = props;

  const { id, loading, excerpt, contact, last_message_at, unread } =
    conversation;

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const { organization } = useContext(UserContext);
  const { showAlert, showMenu } = useContext(UIContext);
  const { loadConversation, updateConversations, updateLocalConversations } =
    useContext(InboxContext);

  // Folders
  const folders =
    organization.folders?.filter((f) =>
      conversation.contact.folder_ids?.includes(f.id),
    ) || [];

  // Click events or when selecting
  const handleClick = () => {
    if (selecting) {
      onSelect();
      return;
    }
    if (isApp) return;
    openConversation();
  };

  const handleLongPress = () => {
    if (scrollingRef.current || swiping.current || menuOpen) return;
    setSelecting(true);
    onSelect();
  };

  const openConversation = () => {
    if (scrollingRef.current) return;
    updateLocalConversations([id], { unread: false });
    let destination = `${location.pathname}/conversations/${id}`;
    if (location.pathname.includes("/conversations")) {
      destination = location.pathname.replace(
        /(conversations.*)/,
        `conversations/${id}`,
      );
    }
    navigate(destination);
  };

  // Actions

  // Move folder
  const [folderDrawer, setFolderDrawer] = useState(false);
  const handleMove = () => {
    if (swiping.current) return;
    closeMenu();
    setFolderDrawer(true);
  };

  // Mark
  const handleMark = () => {
    showMenu({
      title: t("inbox.conversation.mark_as"),
      actions: [
        {
          label: unread
            ? t("inbox.actions.mark_as_read")
            : t("inbox.actions.mark_as_unread"),
          action: () => handleRead(!unread),
        },
        {
          label: t("inbox.actions.mark_as_replied"),
          action: handleReplied,
        },
        {
          label: t("inbox.actions.move_to_spam"),
          action: handleSpam,
        },
      ],
    });
  };

  // Read
  const handleRead = (unread) => {
    updateConversations([id], { unread });
  };

  // replied
  const handleReplied = () => {
    updateConversations([id], { marked_replied: true });
  };

  // Spam
  const handleSpam = () => {
    if (swiping.current) return;
    showAlert({
      title: t("inbox.conversation.move_to_spam_confirm", {
        object: contact?.name || "conversation",
      }),
      confirm: t("shared.confirm"),
      onSubmit: confirmSpam,
    });
    closeMenu();
  };

  const confirmSpam = () => {
    updateConversations([id], { spam: true });
  };

  // Swipe menu (mobile)

  const container = useRef(null);
  const swipable = useRef(null);
  const menu = useRef(null);

  const OPEN_MENU_DISTANCE = -160;
  const MINIMUM_SWIPE_DISTANCE = 10;

  const swiping = useRef(false);
  const startPosition = useRef(null);
  const initialTransform = useRef(0);
  const longPressTimeout = useRef(null);
  // Careful, state updates trigger re-render
  const [menuOpen, setMenuOpen] = useState(false);

  // START

  const handleTouchStart = (evt) => {
    if (scrollingRef.current || selecting) return;

    longPressTimeout.current = setTimeout(handleLongPress, 600);

    startPosition.current = {
      x: evt.changedTouches[0].pageX,
      y: evt.changedTouches[0].pageY,
    };

    initialTransform.current = extractTransformPosition(swipable.current);
  };

  // MOVE

  const handleTouchMove = (evt) => {
    if (scrollingRef.current || selecting) {
      closeMenu();
      return;
    }

    clearTimeout(longPressTimeout.current);

    const delta = startPosition.current
      ? {
          x: Math.round(evt.changedTouches[0].pageX - startPosition.current.x),
          y: Math.round(evt.changedTouches[0].pageY - startPosition.current.y),
        }
      : {
          x: 0,
          y: 0,
        };

    // don't register is swipe is not enough
    if (delta.x > -MINIMUM_SWIPE_DISTANCE) return;

    // real swipe was started
    if (!swiping.current) {
      swipable.current.classList.remove("transition-transform");
      swiping.current = true;
    }
    onSwipeMove();

    let transformX = 0;

    // swipe less than menu distance
    if (delta.x >= OPEN_MENU_DISTANCE) {
      transformX = delta.x;
    } else if (
      // dampen the swipe if it's more than menu distance but less than max
      delta.x < OPEN_MENU_DISTANCE &&
      delta.x >= OPEN_MENU_DISTANCE - 100
    ) {
      transformX = (delta.x - OPEN_MENU_DISTANCE) / 2 + OPEN_MENU_DISTANCE;
    }

    if (transformX != 0) {
      swipable.current.style.transform = `translateX(${
        initialTransform.current + transformX
      }px)`;
    }
  };

  // END

  const handleTouchEnd = (evt) => {
    setTimeout(() => {
      swiping.current = false;
      onSwipeEnd();
    }, 50);

    clearTimeout(longPressTimeout.current);

    if (scrollingRef.current || selecting) {
      closeMenu();
      return;
    }
    swipable.current.classList.add("transition-transform");

    const delta = startPosition.current
      ? {
          x: Math.round(evt.changedTouches[0].pageX - startPosition.current.x),
          y: Math.round(evt.changedTouches[0].pageY - startPosition.current.y),
        }
      : {
          x: 0,
          y: 0,
        };

    //  if similar to click
    if (
      Math.abs(delta.x) < MINIMUM_SWIPE_DISTANCE &&
      Math.abs(delta.y) < 1 &&
      !menuOpen
    ) {
      openConversation();
      return;
    }

    if (delta.x >= MINIMUM_SWIPE_DISTANCE) {
      closeMenu();
    } else if (delta.x < -50) {
      startPosition.current = null;
      setMenuOpen(true);
      swipable.current.style.transform = `translateX(${OPEN_MENU_DISTANCE}px)`;
    } else {
      setTimeout(closeMenu, 50);
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
    startPosition.current = null;
    swipable.current.style.transform = `translateX(0)`;
  };

  // Close menu if we touch anywhere else on the screen

  const handleWindowTouchStart = (evt) => {
    if (!container.current.contains(evt.target)) {
      closeMenu();
    }
  };

  // dropdown menu (desktop)
  const handleDesktopMenu = (evt) => {
    evt.stopPropagation();
    showMenu({
      title: contact.name,
      actions: [
        {
          label: t("inbox.conversation.move_to_folder"),
          action: handleMove,
        },
        {
          label: t("inbox.conversation.mark_as"),
          action: handleMark,
        },
      ],
    });
  };

  // Effects
  useEffect(() => loading && loadConversation(id), [loading]);
  useEffect(() => {
    window.addEventListener("touchstart", handleWindowTouchStart);
    return () => {
      window.removeEventListener("touchstart", handleWindowTouchStart);
    };
  }, []);

  return (
    <div
      className="w-full h-16 flex-shrink-0 border-b border-neutral-100 overflow-hidden relative select-none"
      ref={container}
    >
      <div
        className={`absolute inset-0.25 flex bg-primary items-stretch justify-end`}
        ref={menu}
      >
        <button
          className="w-20 text-center border-0 bg-primary text-white focus:text-white/60"
          onClick={handleMove}
        >
          {t("inbox.conversation.move")}
        </button>
        <button
          className="w-20 text-center border-0 bg-neutral-600 text-white focus:text-white/60"
          onClick={handleMark}
        >
          {t("inbox.conversation.mark_as")}
        </button>
      </div>
      <div
        className={`absolute inset-0 flex items-center justify-between overflow-hidden cursor-pointer sm:hover:bg-neutral-100 ${
          active ? "bg-neutral-100" : "bg-white"
        } transform-gpu touch-pan-y`}
        ref={swipable}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {selecting ? <CheckCircle checked={selected} className="ml-3" /> : null}
        <ConversationItemInfo
          loading={loading}
          name={contact?.name || contact?.username}
          contact={contact}
          folders={folders}
          activeFolder={activeFolder}
          excerpt={excerpt}
          last_message_at={last_message_at}
          statusIcon={
            showStatus ? (
              <ConversationStatusIcon conversation={conversation} />
            ) : null
          }
          desktopEllipsis={handleDesktopMenu}
          unread={unread}
          smaller={smaller}
        />
      </div>
      {folderDrawer && (
        <FoldersDrawer
          contacts={[conversation.contact]}
          onSubmit={(contacts) => {
            updateLocalConversations([id], {
              contact: {
                ...conversation.contact,
                folder_ids: contacts[0].folder_ids,
              },
            });
          }}
          onClose={() => setFolderDrawer(false)}
        />
      )}
    </div>
  );
}
