import React, { useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { XMarkIcon } from "@heroicons/react/20/solid";

import { CampaignsContext } from "~/contexts/campaigns-context";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import ErrorComponent from "~/components/utils/ErrorComponent";
import CampaignMetrics from "./CampaignMetrics";
import ActionResults from "./ActionResults";
import orderActions from "../automation/flow/orderActions";
import CampaignLimitReached from "./CampaignLimitReached";
import CampaignDestination from "./CampaignDestination";

export default function CampaignResults(props) {
  const { campaignId, handleClose = () => {} } = props;

  const { loadCampaign } = useContext(CampaignsContext);

  const [campaign, setCampaign] = useState({ loaded: false });

  useEffect(async () => {
    const res = await loadCampaign(campaignId);
    setCampaign(res);
  }, [campaignId]);

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <div className="w-full h-full flex flex-col overflow-hidden">
        <div className="flex items-center justify-between p-4 border-b">
          {campaign?.title ? (
            <div className="space-y-2">
              <div className="text-medium">{campaign?.title}</div>
              <CampaignDestination campaign={campaign} />
            </div>
          ) : (
            <SkeletonLoader width={200} height={20} />
          )}
          <button className="p-2" onClick={handleClose}>
            <XMarkIcon className="w-6" />
          </button>
        </div>
        <CampaignMetrics campaign={campaign} />
        <CampaignLimitReached campaign={campaign} setCampaign={setCampaign} />
        <div className="flex-grow overflow-auto py-8 px-10 space-y-6 dots-background">
          {campaign?.actions?.length
            ? orderActions(campaign.actions).map((action, index) => (
                <ActionResults key={index} action={action} />
              ))
            : null}
        </div>
      </div>
    </ErrorBoundary>
  );
}
