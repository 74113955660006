import React from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import { XMarkIcon } from "@heroicons/react/20/solid";
import FolderPickerField from "../../inbox/folders/FolderPickerField";

export default function SurveyOptionsForm(props) {
  const { t } = useTranslation();

  const { options, setOptions } = props;

  const handleChangeOption = (index, field) => (value) => {
    setOptions((options) =>
      options.map((option, i) =>
        i === index ? { ...option, [field]: value } : option,
      ),
    );
  };

  const addOption = () => {
    setOptions((options) => [
      ...options,
      { title: "", folder_id: null, reply: "" },
    ]);
  };

  const removeOption = (index) => {
    const option = options[index];
    if (option.id) {
      setOptions((options) =>
        options.map((o) => (o.id === option.id ? { ...o, _destroy: true } : o)),
      );
    } else {
      setOptions((options) => options.filter((_, i) => i !== index));
    }
  };

  const visibleOptions = options.filter((o) => !o._destroy);

  return (
    <div className="space-y-4">
      {options.map(
        (option, index) =>
          !option._destroy && (
            <div
              key={index}
              className="border p-2 rounded-md relative group mb-3"
            >
              <button
                className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center bg-white border shadow rounded-full text-darker-gray opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={() => removeOption(index)}
              >
                <XMarkIcon className="w-3" />
              </button>
              <Input
                placeholder={t(
                  `surveys.options.option_placeholder.${index % 3}`,
                )}
                value={option.title}
                onChange={handleChangeOption(index, "title")}
                autoFocus={!option.title}
                emojiPicker
                maxLength={20}
                className="mb-2"
              />
              <div className="flex items-center space-x-2 mb-2 overflow-hidden">
                <div className="text-xs flex-grow whitespace-nowrap flex-shrink-0">
                  {t("surveys.options.add_to_folder")}
                </div>
                <FolderPickerField
                  value={option.folder_id}
                  onPick={handleChangeOption(index, "folder_id")}
                />
              </div>
              <div className="flex items-center space-x-2">
                <div className="text-xs whitespace-nowrap flex-shrink-0">
                  {t("surveys.options.and_reply")}
                </div>
                <Input
                  className="flex-grow"
                  placeholder={t(`surveys.options.reply_placeholder`)}
                  value={option.reply}
                  onChange={handleChangeOption(index, "reply")}
                  emojiPicker
                />
              </div>
            </div>
          ),
      )}
      {visibleOptions.length < 15 && (
        <Button onClick={addOption} label={t(`surveys.options.add_option`)} />
      )}
    </div>
  );
}
