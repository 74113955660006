import { Bars3BottomLeftIcon } from "@heroicons/react/20/solid";
import ContactFolders from "../inbox/folders/ContactFolders";
import Badge from "~/components/elements/Badge";
import ContactFollowerGrowth from "./ContactFollowerGrowth";
import InlineTooltip from "~/components/shared/InlineTooltip";
import RelativeDate from "~/components/utils/RelativeDate";
import formatMessageText from "~/utils/formatMessageText";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import { useContext } from "react";
import { ContactsContext } from "../../contexts/contacts-context";

export default function contactsTableColumns(handleSort) {
  const { t } = useTranslation();

  const { contactProperties = [] } = useContext(ContactsContext);

  const customColumns = contactProperties.map((prop) => {
    return {
      label: prop.title,
      accessor: (contact) =>
        contact.properties?.find((p) => p.contact_property_id === prop.id)
          ?.value,
    };
  });

  return [
    {
      label: t("contacts.columns.username"),
      name: "username",
      className: "!pl-0",
      accessor: (contact) => (
        <div className="flex items-center justify-between px-2">
          <div className="font-medium text-black">
            {contact?.profile_picture && (
              <img
                className="w-5 h-5 rounded-full inline-block mr-2"
                src={contact?.profile_picture}
              />
            )}
            {contact.username}
          </div>
          {contact.conversation_id && (
            <div className="flex space-x-2 opacity-0 transition-opacity group-hover/row:opacity-100">
              <Button
                size="extra-small"
                to={`/contacts/${contact.id}`}
                label={t("contacts.edit")}
                onClick={(evt) => evt.stopPropagation()}
              />
              <Button
                size="extra-small"
                to={`/contacts/conversations/${contact.conversation_id}`}
                label={t("contacts.view_conversation")}
                onClick={(evt) => evt.stopPropagation()}
              />
            </div>
          )}
        </div>
      ),
      sort: handleSort("username"),
    },
    {
      label: t("contacts.columns.name"),
      name: "name",
      accessor: (contact) => contact.real_name || contact.name,
      sort: handleSort("name"),
    },
    {
      label: t("contacts.columns.email"),
      name: "email",
      accessor: (contact) => contact.email,
      sort: handleSort("email"),
    },
    {
      label: t("contacts.columns.phone"),
      name: "phone",
      accessor: (contact) => contact.phone,
      sort: handleSort("phone"),
    },
    {
      label: t("contacts.columns.folders"),
      accessor: (contact) => <ContactFolders folderIds={contact.folder_ids} />,
    },
    {
      label: t("contacts.columns.follower"),
      accessor: (contact) =>
        contact.is_follower ? (
          <Badge color="green" label={"Follower"} className="inline-block" />
        ) : null,
    },
    {
      label: t("contacts.columns.language"),
      accessor: (contact) =>
        contact.language ? t("languages." + contact.language) : null,
    },
    {
      label: t("contacts.columns.follower_count"),
      accessor: (contact) => (
        <div className="font-medium">{contact.follower_count}</div>
      ),
      name: "follower_count",
      sort: handleSort("follower_count"),
    },
    // {
    //   label: t("contacts.columns.weekly_growth"),
    //   accessor: (contact) => (
    //     <ContactFollowerGrowth value={contact.follower_growth} />
    //   ),
    // },
    {
      label: t("contacts.columns.bio"),
      accessor: (contact) =>
        contact.bio ? (
          <InlineTooltip align="right" text={formatMessageText(contact.bio)}>
            <div className="p-2 rounded bg-lighter-gray">
              <Bars3BottomLeftIcon className="w-4" />
            </div>
          </InlineTooltip>
        ) : null,
    },
    ...customColumns,
    {
      label: t("contacts.columns.last_message"),
      name: "last_message_at",
      className: "text-right",
      accessor: (contact) => <RelativeDate date={contact.last_message_at} />,
      sort: handleSort("last_message_at"),
    },
  ];
}
