import React from "react";
import ContactPicture from "~/components/shared/ContactPicture";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "~/components/utils/SkeletonLoader";

export default function ContactHeader(props) {
  const { t } = useTranslation();

  const {
    contact,
    right = null,
    large = false,
    linkToProfile = false,
    loading = false,
    className,
  } = props;

  return (
    <div
      className={`flex items-center space-x-4 justify-between select-none ${className}`}
    >
      <div>
        <div className="flex items-center">
          <ContactPicture
            contact={contact}
            large={large}
            linkToProfile={linkToProfile}
            className="mt-0.5 mr-3"
            loading={loading}
          />
          <div className="flex flex-col justify-center">
            {loading ? (
              <SkeletonLoader width={100} height={20} />
            ) : (
              <div className="leading-tight">
                {contact?.name || contact?.username || t("payments.no_contact")}
              </div>
            )}
            {loading ? (
              <SkeletonLoader width={100} height={12} className="mt-1" />
            ) : (
              contact?.name && (
                <div className="text-xs text-neutral-400">
                  {contact?.username || contact?.email}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {right}
    </div>
  );
}
