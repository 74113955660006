import React from "react";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import { useTranslation } from "react-i18next";

export default function SurveyQuestionsPreview(props) {
  const { t } = useTranslation();

  const { survey, questions } = props;

  return (
    <div className="bg-black rounded-xl p-4 w-full max-w-xs space-y-4">
      <div className="bg-white rounded-lg p-4 space-y-2">
        <div className="font-medium">{survey?.title}</div>
        <div className="text-sm">{survey?.subtitle}</div>
      </div>
      <div className="bg-white rounded-lg p-4 space-y-4">
        {questions.map((question, index) => (
          <Input
            key={index}
            label={question.title}
            type={question.field_type}
            optional={!question.required}
          />
        ))}
      </div>
      <Button
        className="w-full max-w-sm"
        style="primary"
        label={t("surveys.questions.submit")}
        loadingOnClick
      />
    </div>
  );
}
