import { VariableIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { delay, keys } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import Textarea from "react-expanding-textarea";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import classNames from "../../../../utils/classNames";
import Button from "../../../elements/Button";
import Modal from "../../../shared/Modal";
import CustomNode from "./CustomNode";

const variables = {
  contact: [
    "id",
    "username",
    "name",
    "email",
    "phone",
    "follower_count",
    "profile_picture",
  ],
  trigger: ["message", "comment", "date", "ref"],
};

export default function MessageNode(props) {
  const { t } = useTranslation();

  const { editAction } = useContext(FlowContext);

  const { data, selected } = props;

  const { id, content } = data;

  const [localContent, setLocalContent] = useState(content);

  const updateContent = (newContent) => {
    editAction(id, { content: newContent });
    if (newContent != localContent) setLocalContent(newContent);
  };

  const inputRef = useRef(null);
  const cursorRangeRef = useRef(null);

  const handleBlur = (evt) => {
    // Save cursor position
    cursorRangeRef.current = [
      evt.target.selectionStart,
      evt.target.selectionEnd,
    ];
    setTimeout(() => {
      updateContent(localContent);
    }, 200);
  };

  const [variablesDialog, setVariablesDialog] = useState(false);

  const handleInsertVariable = (variable) => {
    const newContent = cursorRangeRef.current
      ? content.slice(0, cursorRangeRef.current[0]) +
        variable +
        content.slice(cursorRangeRef.current[1])
      : content + variable;
    // update content
    updateContent(newContent);
    // restore cursor position
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        cursorRangeRef.current[0] + variable.length,
        cursorRangeRef.current[0] + variable.length,
      );
    }, 50);
    setVariablesDialog(false);
  };

  useEffect(() => {
    if (selected) delay(() => inputRef.current.focus(), 50);
  }, [selected]);

  return (
    <CustomNode selected={selected} help="https://youtu.be/OYhhlWwnC_w">
      <div
        className={`relative w-full max-w-[220px] ${selected ? "z-50" : null}`}
      >
        <Textarea
          ref={inputRef}
          className={classNames(
            "nodrag nopan block w-full text-message border-none outline-none resize-none focus:outline-none whitespace-pre-wrap ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100",
            selected ? "ring-opacity-100" : null,
          )}
          placeholder={t("automation.scenarios.actions.message.placeholder")}
          value={localContent}
          onChange={(evt) => setLocalContent(evt.target.value)}
          onBlur={handleBlur}
        />
        <div
          className={classNames(
            "absolute top-full inset-x-0 flex space-x-2 items-center justify-center mt-2 transition-opacity",
            selected ? "opacity-100" : "opacity-0 pointer-events-none",
          )}
        >
          <Button
            size="small"
            label={t("automation.scenarios.actions.message.insert_variable")}
            icon={VariableIcon}
            onClick={() => setVariablesDialog(true)}
          />
        </div>
      </div>
      {variablesDialog && (
        <Modal
          onClose={() => setVariablesDialog(false)}
          className="max-w-[520px]"
        >
          <div className="p-4 space-y-4">
            <div className="flex justify-between items-center">
              <div className="font-medium">
                {t("automation.scenarios.actions.message.insert_variable")}
              </div>
              <XMarkIcon
                className="w-6"
                onClick={() => setVariablesDialog(false)}
              />
            </div>
            {keys(variables).map((category) => (
              <div className="space-y-2">
                <div className="font-medium text-sm">
                  {t(`automation.variables.${category}.title`)}
                </div>
                <div className="flex flex-wrap gap-2">
                  {variables[category].map((variable) => (
                    <Button
                      label={t(
                        `automation.variables.${category}.fields.${variable}`,
                      )}
                      onClick={() =>
                        handleInsertVariable(`{{${category}.${variable}}}`)
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </CustomNode>
  );
}
