import React, { useContext, useState } from "react";
import { FlowContext } from "~/contexts/flow-context";
import triggerTypeOptions from "./triggerTypeOptions";
import { useTranslation } from "react-i18next";
import StoryPickerField from "../../../content/StoryPickerField";
import Input from "~/components/elements/Input";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import TriggerKeywordsOption from "./TriggerKeywordsOption";
import { UserContext } from "~/contexts/user-context";
import { compact } from "lodash";

export default function StoryReplyOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { organization } = useContext(UserContext);
  const { editTriggerOptions } = useContext(FlowContext);

  const options = compact([
    ...triggerTypeOptions.story_reply.options,
    organization.dev && "caption_keyword",
  ]);

  const handleOptionChange = (evt) => {
    editTriggerOptions(trigger.id, {
      story_reply_option: evt.target.value,
    });
  };

  return (
    <div className="flex flex-col space-y-3">
      <select
        className="input-select"
        value={trigger.options.story_reply_option}
        onChange={handleOptionChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {t(
              "automation.scenarios.trigger_type.story_reply.options." + option,
            )}
          </option>
        ))}
      </select>
      {trigger.options.story_reply_option === "specific" && (
        <StoryPickerField
          value={trigger.options.story_id}
          onChange={(story_id) => editTriggerOptions(trigger.id, { story_id })}
        />
      )}
      {trigger.options.story_reply_option === "keywords" && (
        <TriggerKeywordsOption trigger={trigger} showNotice />
      )}
      {trigger.options.story_reply_option === "caption_keyword" && (
        <>
          <Input
            placeholder={t("automation.scenarios.trigger_node.keyword")}
            value={trigger.options.caption_keyword}
            onChange={(caption_keyword) => {
              if (trigger.options.caption_keyword === caption_keyword) return;
              editTriggerOptions(trigger.id, { caption_keyword });
            }}
            changeOnBlur
          />
          <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
            <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
            {t("automation.scenarios.trigger_type.story_reply.keyword_notice")}
          </div>
        </>
      )}
    </div>
  );
}
