import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ConnectInstagramChecklist from "./connect_instagram/ConnectInstagramChecklist";
import { UserContext } from "~/contexts/user-context";
import NoPage from "../../errors/NoPage";
import ManyPages from "../../errors/ManyPages";
import NoInstagram from "../../errors/NoInstagram";
import ConnectInstagramHelp from "./connect_instagram/ConnectInstagramHelp";
import Modal from "../../shared/Modal";
import OauthTutorial from "./connect_instagram/OauthTutorial";

export default function ConnectInstagram() {
  const { t } = useTranslation();

  const { organization } = useContext(UserContext);

  const [showTutorial, setShowTutorial] = useState(false);

  const connectInstagramSection = () => {
    if (organization.facebook_error_code == "onboarding") {
      switch (organization.facebook_error_message) {
        case "No page selected":
          return <NoPage showTutorial={() => setShowTutorial(true)} />;
        case "Multiple pages selected":
          return <ManyPages showTutorial={() => setShowTutorial(true)} />;
        case "No Instagram account":
          return <NoInstagram showTutorial={() => setShowTutorial(true)} />;
        default:
          return (
            <ConnectInstagramChecklist
              showTutorial={() => setShowTutorial(true)}
            />
          );
      }
    } else {
      return (
        <ConnectInstagramChecklist showTutorial={() => setShowTutorial(true)} />
      );
    }
  };

  return (
    <div className="max-w-[912px] mx-auto space-y-8">
      <div className="space-y-4">
        <div className="font-black text-3xl sm:text-5xl text-headings whitespace-pre-line">
          {t("onboarding.connect_instagram.title")}
        </div>
        <div className="leading-6 text-muted">
          {t("onboarding.connect_instagram.description")}
        </div>
      </div>
      <div className="rounded-2xl overflow-hidden border shadow-sm bg-white grid grid-cols-2">
        <div className="p-8">{connectInstagramSection()}</div>
        <ConnectInstagramHelp />
      </div>
      {showTutorial && (
        <Modal onClose={() => setShowTutorial(false)}>
          <OauthTutorial onClose={() => setShowTutorial(false)} />
        </Modal>
      )}
    </div>
  );
}
